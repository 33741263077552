<template>
  <div>
    <el-form :model="form"
             label-width="100px">
      <el-row :gutter="10">
        <el-col :lg="6"
                :md="12"
                :sm="12"
                :xl="6"
                :xs="24">
          <el-form-item label="科目">
            <el-select style="width:100%"
                       v-model="form.subject_id"
                       clearable
                       @change="clearForm"
                       placeholder="选择科目">
              <el-option v-for="item in subjectList"
                         :key="item.subject_id"
                         :label="item.subject_name"
                         :value="item.subject_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :lg="6"
                :md="12"
                :sm="12"
                :xl="6"
                :xs="24">
          <el-form-item label="来源">
            <el-select style="width:100%"
                       v-model="form.source_id"
                       filterable
                       clearable
                       placeholder="选择来源">
              <el-option v-for="item in sourceList"
                         :key="item.source_id"
                         :label="item.source_name"
                         :value="item.source_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :lg="6"
                :md="12"
                :sm="12"
                :xl="6"
                :xs="24">
          <el-form-item label="状态">
            <el-select style="width:100%"
                       v-model="form.status"
                       filterable
                       @change="search"
                       clearable
                       placeholder="请选择">
              <el-option v-for="item in statusList"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :lg="6"
                :md="12"
                :sm="12"
                :xl="6"
                :xs="24">
          <el-button type="primary"
                     @click="search">查询</el-button>
          <el-button type="primary"
                     @click="refresh">刷新</el-button>

        </el-col>
      </el-row>
    </el-form>
    <el-table border
              stripe
              :data="tableData"
              highlight-current-row
              :max-height="$store.state.tableHeight + 30"
              style="width: 100%">
      <el-table-column prop="question_id"
                       align="center"
                       width="100"
                       v-if="tableColumns[0].isShow"
                       label="编号" />
      <el-table-column prop="source_name"
                       align="center"
                       v-if="tableColumns[1].isShow"
                       label="题目来源" />
      <el-table-column prop="question_type_name"
                       align="center"
                       v-if="tableColumns[2].isShow"
                       label="题目类型" />
      <el-table-column prop="subject_name"
                       align="center"
                       width="100"
                       v-if="tableColumns[3].isShow"
                       label="科目" />
      <el-table-column prop="question_score"
                       align="center"
                       width="120"
                       v-if="tableColumns[4].isShow"
                       label="分数" />
      <el-table-column prop="question_category_name"
                       align="center"
                       v-if="tableColumns[5].isShow"
                       label="题目类别" />
      <el-table-column prop="status_cn"
                       align="center"
                       v-if="tableColumns[6].isShow"
                       label="状态">
        <template slot-scope="{row}">
          <el-tooltip class="item"
                      :disabled="row.status != 4"
                      effect="dark"
                      :content="'拒绝原因:' + row.fail_reason"
                      placement="top">
            <div :class="getClass(row)">
              {{row.status_cn}}
              <img src="@/assets/teacher_module/weitongguo.png"
                   v-if="row.status == 4"
                   style="width:15px;height:15px;vertical-align: middle;" />
            </div>
          </el-tooltip>

        </template>
      </el-table-column>
      <el-table-column align="center"
                       width="200"
                       v-if="tableColumns[7].isShow"
                       label="操作">
        <template slot="header">
          <i class="el-icon-setting"
             slot="header"
             @click="showController"></i>
          操作
        </template>
        <template slot-scope="{row}">
          <div class="text_btn_primary"
               @click="lookTopic(row)">查看题目</div>

          <div class="text_btn_primary"
               v-if="row.status == 1"
               @click="uploadFile(row)">上传微课</div>

          <div class="text_btn_primary"
               v-if="row.status == 2 || row.status == 3||row.status == 4"
               @click="preview(row)">查看微课</div>

          <div class="text_btn_danger"
               @click="aginUpload(row)"
               v-if="row.status == 4 && row.again_num <= 1 ">再次上传</div>
        </template>
      </el-table-column>
    </el-table>
    <Shuttle ref="ShuttleRef" />

    <previewDialog ref="previewDialogRef" />

    <Info ref="InfoRef">
      <div slot="button"
           v-if="showButton"
           class="button_Warp">
        <div @click="acceptORreject(1)">接受</div>
        <div @click="acceptORreject(2)">不接受</div>
      </div>
    </Info>
    <upFile ref="upFileRef" />
    <pagination ref="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" />
  </div>
</template>
<script>
import previewDialog from './components/preview.vue'

import Info from '@/components/checkInfo/index.vue'
import upFile from './components/upFile.vue'
export default {
  data () {
    return {
      form: {
        subject_id: '',
        source_id: '',
        status: ''
      },
      showButton: true,
      break: '',
      infoForm: {},
      subjectList: [],
      tableData: [],
      page: 1,
      size: 10,
      totalElements: 0,
      sourceList: [],
      statusList: [
        { label: '未审核', value: 1 },
        { label: '未通过', value: 2 },
        { label: '已通过', value: 3 },
        { label: '已过期', value: 4 }
      ],
      pageName: 'cz_teacher_module_taskList_admin',
      tableColumns: [
        { label: '编号', isShow: true },
        { label: '题目来源', isShow: true },
        { label: '题目类型', isShow: true },
        { label: '科目', isShow: true },
        { label: '分数', isShow: true },
        { label: '题目类别', isShow: true },
        { label: '状态', isShow: true },
        { label: '操作', isShow: true },
      ],
    }
  },
  components: {
    Info, upFile, previewDialog
  },
  mounted () {
    this.getSubject()
  },
  methods: {
    preview (row) {

    },
    lookTopic (row) {
      //修改本页url参数，用于获取题目
      if (row.is_accept != 0 || row.status == 5) {
        this.showButton = false
      } else {
        this.showButton = true
      }
      this.$router.push('/teacher_module/taskList?taskId=' + row.id + '&id=' + row.question_id)
      this.$refs.InfoRef.dialogVisible = true
    },
    getSubject () {
      this.$http({
        url: '/api/v1/papers/subject',
        method: 'get'
      }).then(res => {
        this.subjectList = res.data.subject
      })
    },
    getSourceList () {
      this.form.source_id = ''
      this.$http({
        url: '/api/v1/school/get_source',
        method: 'get',
        params: {
          subject_id: this.form.subject_id
        }
      }).then(res => {
        this.sourceList = res.data.list
        this.search()
      })
    },
    initData (page, limit) {
      this.form.page = page
      this.form.limit = limit
      this.$http({
        url: '/api/v1/course/task_lst',
        method: 'get',
        params: this.form
      }).then(res => {
        this.tableData = res.data.list
        this.totalElements = res.data.count
      })
    },
    search () {
      this.$refs.pagination.toFirstPage()
    },
    clearForm () {
      if (this.form.subject_id == '') {
        this.form.source_id = ''
        this.sourceList = []
        return
      }
      this.getSourceList()
    },
    refresh () {
      this.$refs.pagination.shuaxin()
    },
    uploadFile (item) {
      let form = {
        question_id: item.question_id,
        description: '',
        task_id: item.id
      }
      this.$refs.upFileRef.url = '/api/v1/course/tc_create'
      this.$refs.upFileRef.form = form
      this.$refs.upFileRef.dialogVisible = true
    },
    getColor (index) {
      let color = [
        '#FF827C', '#FF827C', '#58AAFF', '#20c192', '#FF827C', '#FF827C',
      ]
      return color[index]
    },
    acceptORreject (num) {
      if (num == 1) {
        this.postData(1)
      } else {
        this.$refs.InfoRef.innerVisible = true
      }
    },
    postData (num, reason = '') {
      this.$http({
        url: '/api/v1/course/accept',
        method: 'post',
        data: {
          id: this.$route.query.taskId,
          is_accept: num,
          reason
        }
      }).then(res => {
        this.$notify({
          title: '提示',
          message: '操作!',
          type: 'success'
        });
        // this.$refs.InfoRef.handleClose()
        this.search()
        this.$refs.InfoRef.handleClose()
      })
    },
    aginUpload (item) {
      let form = {
        question_id: item.question_id,
        description: '',
        task_id: item.id
      }
      this.$refs.upFileRef.form = form
      this.$refs.upFileRef.url = '/api/v1/course/again'
      this.$refs.upFileRef.dialogVisible = true
    },
    getClass (row) {
      if (row.status == 0 || row.status == 1 || row.status == 4 || row.status == 5 || row.status == 6 || row.status == 7) {
        return 'notpass'
      } else if (row.status == 2) {
        return 'weishanhe'
      } else {
        return 'pass'
      }
    },
    preview (row) {
      this.$refs.previewDialogRef.url = row.hash_id
      this.$refs.previewDialogRef.dialogVisible = true
    },
    showController () {
      this.$refs.ShuttleRef.columns = JSON.parse(JSON.stringify(this.tableColumns))
      this.$refs.ShuttleRef.dialogVisible = true
    },
    getColumns (arr) {
      this.tableColumns = arr
      window.localStorage.setItem(this.pageName, JSON.stringify(arr))
      this.$forceUpdate()
    }
  }
}
</script>

<style lang="scss" scoped>
.button_Warp {
  display: flex;
  justify-content: center;
  div {
    width: 72px;
    height: 35px;
    margin-right: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;
    &:nth-child(1) {
      background: #1890ff;
    }
    &:nth-child(2) {
      background: #f75d3e;
    }
  }
}
// .text {
//   display: flex;
//   align-items: center;
//   cursor: pointer;
//   justify-content: center;
// }
</style>